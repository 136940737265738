.card {

    .card-data {
        background-color: rgb(26, 35, 126);
        color: #fff;
        text-align: center;
        height: auto;
        padding: 10px;
        ul {
            margin-bottom: 0;
            font-size: .9rem;
            li {
                display: inline;
                .fa-clock-o {
                    margin-left: 10px;
                }
            }
            a {
                color: #fff;
                .fa {
                    margin-left: 5px;
                    margin-right: 3px
                }
            }
        }
    }
    &.slick-slide {
        margin: 0 5px 5px 5px;
        img {
            margin: auto;
        }
    }
}
