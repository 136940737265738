$slick-font-family: "Font Awesome 5 Free";
$slick-prev-character: fa-content($fa-var-chevron-left);
$slick-next-character: fa-content($fa-var-chevron-right);
$slick-dot-character: "\f111";
$slick-loader-path: "../img/overlays/";
@import "../node_modules/slick-carousel/slick/slick.scss";
@import "../node_modules/slick-carousel/slick/slick-theme.scss";


section#bil {
    /* @include media-breakpoint-up(lg) {
        .price-overlay {
            right: 58px; // if max-width 1024 on slick-slide
        }
    } */
    .counter-info {
        @extend .small;
        @extend .text-center;
    }
    .slider-for {
        .slick-slide {
            .image {
                width: 100%;
                padding-bottom: 75%;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
            a {
                background-color: rgba(51, 51, 51, 0.5);
                position: absolute;
                right: 40%;
                bottom: 45%;
                border-radius: 50%;
                padding: 30px;
                .slider-fullscreen {
                    opacity: 0.7;
                    padding: 5px 10px;
                    color: white;
                    z-index: 1000;
                }
                &:hover .slider-fullscreen {
                    opacity: 1;
                }
                @include media-breakpoint-down(md) {
                    display: none;   
                }
            }
        }
    }
    .slider {
        .slick-list {
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        }
    }
    .slidernav {
        margin-top: 30px;
        .slick-slide {
            position: relative;
            max-width: 100%;
            height: auto!important;
            margin: 0px;
            .image {
                background-size: cover;
                width: 100%;
                padding-bottom: 66.5%;
                background-position: center;
                background-repeat: no-repeat;
            }
            opacity: $slick-opacity-default;
            &.slick-current {
                opacity: $slick-opacity-on-hover;
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
                .image:before {
                    background: rgb(242, 76, 90);
                    height: 4px;
                    width: 100%;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    content: "";
                    position: absolute;
                }
            }
        }
    }
    .slick-slider {
        a.prev, a.next {
          position: absolute;
          top: 0;
          bottom: 0;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          -ms-flex-pack: center;
          justify-content: center;
          width: 15%;
          color: #fff;
          text-shadow: 1px 1px 1px #999;
          text-align: center;
          opacity: .5;
          cursor: pointer;
          text-decoration: none;
          transition: all .2s ease-in-out;
          z-index: 100;
          i:before, i:before {
            font-size: 3rem;
          }
          &:hover {
            color: white;
            opacity: 1;
            text-shadow: 2px 2px 2px #000;
            transition: all .2s ease-in-out;
          }
        }
        a.prev {
          left:0;
        }
        a.next {
          right: 0;
        }
        .slick-dots {
          bottom: 0;
          li button:before {
            color: #ffffff;
          }
        }
    }
}

/* .slick-prev, .slick-next {
    width: 35px;
    height: 35px;
    z-index: 1000;
    @extend %fa-icon;
    @extend .fas;
    &:before {
        color: white;
        text-shadow: 2px 2px 2px #000;
        font-size: 2rem;
    }
}
.slick-prev {
    left: -10px;
}
.slick-next {
    right: -10px;
} */

section.bilar.slick-slider {
    .slick-prev:before, .slick-next:before {
        color: grey;
    }
    .slick-slide {
        margin: 5px;
        a {
            display: block;
            box-shadow: 0px 0px 2px #ccc;
            .carimage {
                width: 100%;
                padding-bottom: 70%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                position: relative;
                overflow: hidden;
            }
            h4 {
                text-align: center;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 2rem;
                line-height: 2rem;
                white-space: nowrap!important;
            }
            .carinfo {
                padding: 10px;
                .btn, .price {
                    margin-top: 10px;
                }
                .price {
                    font-size: 80%;
                    padding: 5px;
                    border: 1px solid rgb(204, 204, 204);
                    border-radius: 5px;
                }
                .price span {
                    font-weight: bold;
                    font-size: 1rem;
                }
                i {
                    font-size: 1rem;
                    .fa-info-circle {
                        position: relative;
                        color: grey;
                        left: 5px;
                        margin-left: 20px;
                    }
                    .fa-arrow-down {
                        color: red;
                        margin-right: 20px;
                    }
                }
                .dl-horizontal {
                    margin-bottom: 10px;
                    width: 80%;
                    margin: auto;
                }
                dt, dd {
                    width: 50% !important;
                    float: left;
                    margin: 0;
                }
                dl dt {
                    text-align: left;
                }
                dl dd {
                    text-align: right;
                }
            }
        }
    }
}
