section.bilar:not(.slick-slider) {
    @include make-row();
    @include row-cols(1);
    @include media-breakpoint-up(md) {
        @include row-cols(2);
    }
    @include media-breakpoint-up(lg) {
        @include row-cols(4)
    }
    @extend .justify-content-center;
    .carcontainer {
        @include make-col-ready();
        @extend .mb-3;
        a {
            color: $brand-color;
            display: block;
            box-shadow: 0px 0px 2px #ccc;
            overflow: hidden;
            transition: all 0.5s ease;
            &:hover {
                @extend .z-depth-3;
            }
            .carimage {
                width: 100%;
                padding-bottom: 70%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                position: relative;
                overflow: hidden;
            }
            h4 {
                text-align: center;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 2rem;
                line-height: 2rem;
                white-space: nowrap!important;
            }
            .carinfo {
                padding: 10px;
                .btn, .price {
                    margin-top: 10px;
                }
                .price {
                    font-size: 80%;
                    padding: 5px;
                    border: 1px solid rgb(204, 204, 204);
                    border-radius: 5px;
                }
                .price span {
                    font-weight: bold;
                    font-size: 1rem;
                }
                i {
                    font-size: 1rem;
                    .fa-info-circle {
                        position: relative;
                        color: grey;
                        left: 5px;
                        margin-left: 20px;
                    }
                    .fa-arrow-down {
                        color: red;
                        margin-right: 20px;
                    }
                }
                .dl-horizontal {
                    margin-bottom: 10px;
                    width: 80%;
                    max-width: 300px;
                    margin: auto;
                }
                dt, dd {
                    width: 50% !important;
                    float: left;
                    margin: 0;
                }
                dl dt {
                    text-align: left;
                }
                dl dd {
                    text-align: right;
                }
            }
        }
    }
}

