/*---------------------------------------
   Social icon
-----------------------------------------*/
.social-icon {
    position: relative;
    padding: 0;
    margin: 0;
}

.social-icon li {
    display: inline-block;
    list-style: none;
}

.social-icon li a {
    @extend .primary-color-dark;
    border-radius: 100px;
    font-size: 16px;
    text-decoration: none;
    transition: all 0.4s ease-in-out;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    vertical-align: middle;
    @extend .z-depth-1-half;
}

.social-icon li a:hover {
    @extend .primary-color;
    color: #ffffff;
}
/*---------------------------------------
    Floating icons
-----------------------------------------*/
.btn-floating {
    position: relative;
    margin: 10px;
    cursor: pointer;
    z-index: 1;
    overflow: hidden;
    width: 47px;
    height: 47px;
    padding: 0;
    border-radius: 50%;
    background-color: rgb(170, 102, 204);
    -webkit-transition: .3s;
    transition: .3s;
    display: inline-block;
    color: rgb(255, 255, 255);
    -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
    i {
        width: inherit;
        font-size: 1.25rem;
        line-height: 47px;
        text-align: center;
    }
    &.noclick {
        cursor: default;
    }
}
