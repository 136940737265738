@media print {
    #bil {
        #printcar {
            .objekt-parameters {
                width: 30%;
                float:left;
            }
        
        }
    }
}
