/* fancybox */
@import "jquery.fancybox.min.css";

/* @import "https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.1.25/jquery.fancybox.min.css";

.fancybox-wrap, .fancybox-wrap *{
    -moz-box-sizing: content-box !important;
    -webkit-box-sizing: content-box !important;
    -safari-box-sizing: content-box !important;
    box-sizing: content-box !important;
} */
