/* partners */
.partners {
    @extend .pt-5;
    @extend .pb-5;
    background-color: white;
    color: black;
    img {
        max-height: 150px;
        @extend .img-fluid;
    }
    div.row {
        @extend .d-flex;
        @extend .justify-content-center;
        .item {
            @include media-breakpoint-up(lg) {
                @include make-col(3);
            }
            @include media-breakpoint-down(md) {
                @include make-col(4);
            }
            @include media-breakpoint-down(sm) {
                @include make-col(6);
            }
            @extend .d-flex;
            @extend .align-items-center;
            @extend .justify-content-center;
            .item-contain {
                position: relative;
                width: 100%;
                padding-bottom: 70%;
                .item-content {
                    position:absolute;
                    width:100%;
                    height:70%;
                    img {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                    }
                }
            }
        }
    }
}
