/* Carousel*/

.carousel, .carousel img {
    height: 50vh;
}

@media (max-width: 776px) {
    .carousel {
        height: 100%;
    }
}

.carousel-item {
    height: 100%;
    background-position: center;
}

.carousel-inner {
    height: 100%;
    ul {
        max-width: 80%;
        text-shadow: 2px 2px 3px #000;
    }
    .btn .fa {
        font-size: 2rem;
        margin-right: 10px;
    }
}
