// .carousel-multi-item {
//     margin-bottom: 100px;
//     .carousel-item-next,
//     .carousel-item-prev,
//     .carousel-item.active {
//         display: block;
//     }
//     .carousel-indicators {
//         margin-bottom: -2em;
//         li {
//             height: 20px;
//             max-width: 20px;
//             background-color: #4285F4;
//             margin-bottom: -60px;
//         }
//         .active {
//             height: 25px;
//             max-width: 25px;
//             background-color: #4285F4;
//             border-radius: 50%;
//         }
//
//     }
//     .controls-top {
//         margin-bottom: 30px;
//         .btn-floating {
//             background: #4285F4
//         }
//     }
//     .card {
//         margin: 1px;
//     }
//     .card-cascade.narrower {
//         margin-top: 20px;
//         margin-bottom: 5px;
//     }
//
// }
//
// @media (min-width:768px) {
//     .carousel-multi-item .col-md-4 {
//         float: left;
//         width: 33.333333%;
//         max-width: 100%;
//     }
// }
//
// @media only screen and (max-width:992px) {
//     .carousel-multi-item .carousel-indicators li {
//         margin-bottom: -30px;
//     }
// }
// #myCarousel {
//     .carousel-control 			 { width:  4%; }
//     .carousel-control.left,.carousel-control.right {margin-left:15px;background-image:none;}
//     @media (max-width: 767px) {
//     	.carousel-inner .active.left { left: -100%; }
//     	.carousel-inner .next        { left:  100%; }
//     	.carousel-inner .prev		 { left: -100%; }
//     	.active > div { display:none; }
//     	.active > div:first-child { display:block; }
//
//     }
//     @media (min-width: 767px) and (max-width: 992px ) {
//     	.carousel-inner .active.left { left: -50%; }
//     	.carousel-inner .next        { left:  50%; }
//     	.carousel-inner .prev		 { left: -50%; }
//     	.active > div { display:none; }
//     	.active > div:first-child { display:block; }
//     	.active > div:first-child + div { display:block; }
//     }
//     @media (min-width: 992px ) {
//     	.carousel-inner .active.left { left: -25%; }
//     	.carousel-inner .next        { left:  25%; }
//     	.carousel-inner .prev		 { left: -25%; }
//     }
// }
.carousel-inner .active.left { left: -33%; }
.carousel-inner .next        { left:  33%; }
.carousel-inner .prev        { left: -33%; }
.carousel-control.left,.carousel-control.right {background-image:none;}

.carousel-multi{
	.carousel-inner{
    > .item{
      transition: 500ms ease-in-out left;

      > .media-card{
        background: #333;
        border-right: 10px solid #fff;
			  display: table-cell;
			  width: 1%;

        &:last-of-type{
          border-right: none;
        }
      }
    }
    .active{
      display: table;
      &.left{
        left:-33%;
      }
      &.right{
        left:33%;
      }
    }
    .next{
      left: 33%;
    }
    .prev{
      left: -33%;
    }
    @media all and (transform-3d), (-webkit-transform-3d) {
      > .item{
        // use your favourite prefixer here
        transition: 500ms ease-in-out all;
        backface-visibility: visible;
        transform: none!important;
      }
    }
  }
}
