// Your custom skin
// Skins
$skins: () !default;
$skins: map-merge(
    (
        "bkbil": (
            "skin-primary-color": #566d8f,
            "skin-navbar": #566d8f,
            "skin-footer-color": #566d8f,
            "skin-accent": #2a445a,
            "skin-flat": #a5a4a4,
            "skin-sidenav-item": rgba(0, 0, 0, 0.4),
            "skin-sidenav-item-hover": rgba(0, 0, 0, 0.4),
            "skin-gradient-start": #566d8f,
            "skin-gradient-end": #2a445a,
            "skin-mask-slight": rgba(121, 121, 121, 0.5),
            "skin-mask-light": rgba(121, 121, 121, 0.65),
            "skin-mask-strong": rgba(121, 121, 121, 0.8),
            "skin-sn-child": #c7e019,
            "skin-btn-primary": #2a445a,
            "skin-btn-secondary": #7d8488,
            "skin-btn-default": #353637,
            "skin-text": #fff
        )
    ),
    $skins
);