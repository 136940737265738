/* YOUR CUSTOM STYLES */

@import "custom/all";

main {
    .container {
        @extend .py-2;
        .content {
            @extend .py-2;
        }
    }
}
section {
    @extend .my-5;
}
p.lead {
    @include media-breakpoint-down(md) {
        font-size: 1rem;
    }
}
.fa-ul {
    margin-left: 2em;
}
.view {
    &.title-view {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        height: 40vh;
    }
}
#trygghet {
    .mask {
        background-color: rgba(86, 109, 143, 0.9);
        .container {

            .tryggikon {
                padding: 10px;
                i {
                    font-size: 5rem;
                    text-shadow: #000 2px 2px 2px;
                }
            }
        }
    }
}
/* carmakes */
.carmakes {
    @include make-row;
    @extend .justify-content-center;
    @extend .align-items-center;
    @include row-cols(3);
    @include media-breakpoint-up(md) {
        @include row-cols(7);
    }
    .imgwrapper {
        .image {
            @extend .text-center;
            @extend .p-3;
            @extend .view;
            @extend .zoom;
            height: auto!important;
            img {
                margin: 0 auto;
                @extend .img-fluid;
                filter: opacity(40%);
                &:hover {
                    filter: none;
                }
            }
        }
    }
}

/* cookie concent */
@media screen and (min-width: 500px) {
    .cc_container .cc_logo {
        display: none!important;
    }
}
/*Contact */
.contact-icons {
    margin-left: 4em;
    li {
        padding-top:3px;
        font-size: 1.2em;
        @extend .mb-3;
        a {
            color: black;
        }
    }
}
ul.openhours {
    max-width: 200px;
    span {
        @include float-right;
    }
}
/*Caption*/

.flex-center {
    color: #fff;
}
.navbar .btn-group .dropdown-menu a:hover {
    color: #000 !important;
}
.navbar .btn-group .dropdown-menu a:active {
    color: #fff !important;
}


/* buttons */
.btn .fa {
    margin-top: 0;
}