/* BKbilforum Design Colors */
// Link color

$link-color: #3b401c;

#bilar, .bilar.slick-slider {
    .carcontainer {
        a {
            color: $special-color;
        }
    }
}
