/* Navigation*/

.navbar {
    //background: rgba(255,255,255,0.1);
    border-radius: 0;
    font-weight: 500;
    .navbar-brand {
        max-width: 70%;
        .logo {
            fill: #fff;
        }
    }
    .logos {
        display: none;
        @media only screen and (min-width: 1199px) {
            display: inline-block;
        }
        max-height: 40px;
        div {
            max-width: 50px;
            img {
                @extend .img-fluid ;
            }
        }
    }
    &.navbar-dark {
        .navbar-nav {
            .nav-item {
                .nav-link {
                    text-shadow: 1px 1px 2px #000;
                    @extend .text-uppercase;
                }
            }
        }
    }
/*     &.navbar-light {
        .navbar-nav {
            .nav-item {
                @include media-breakpoint-up(lg) {
                    .nav-link {
                        color: #ffffff;
                        text-shadow: 2px 2px 2px #000;
                    }
                }
            }
        }
    } */
/*     &.navbar-light.top-nav-collapse {
        background-color: #ffffff;
        .navbar-nav .nav-item .nav-link {
            color: #000000;
            text-shadow: none;
        }
    } */
    @include media-breakpoint-down(md) {
        background-color: #ffffff;
        img {
            max-height: 50px;
        }
    }
    .dropdown {
        .dropdown-menu {
            .dropdown-item {
                &:hover {
                    @extend .indigo;
                }
            }
        }
    }
}
.scrolling-navbar {
    -webkit-transition: background .5s ease-in-out, padding .5s ease-in-out;
    -moz-transition: background .5s ease-in-out, padding .5s ease-in-out;
    transition: background .5s ease-in-out, padding .5s ease-in-out;
}
