footer {
    border-top: 4px solid $brand-color;
    @extend .container-fluid;
    @extend .py-3;
    .footer-copyright {
        margin: -15px;
        margin-top: 0;
    }
    .jarallax {
        height: auto;
        min-height: auto;
        margin: -15px;
    }
    ul.fa-ul {
        li {
            margin-top: 6px;
            padding-top: 3px;
        }
    }
    .logo {
        fill: #fff;
        @extend .img-fluid;
        height: 30px;
        @include media-breakpoint-down(md) {
            height: 43px;
        }
    }
    .column-title {
        @extend .white-text;
        @extend .pb-3;
        border-bottom: 2px solid $brand-color;

    }
}

/* Back top */
.go-top {
  background-color: #222;
  bottom: 2em;
  right: 2em;
  color: #ffffff;
  font-size: 32px;
  display: none;
  position: fixed;
  text-decoration: none;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  transition: all 0.4s ease-in-out;
  @media (max-width: 980px) {
    bottom: 1em;
    right: 1em;
  }
}

.go-top:hover {
    @extend .indigo;
    color: #ffffff;
}
.guideto {
    position: absolute;
    left: 7;
    bottom: -150;
    z-index: 1000;
}
