body {
    .view {
        height: 30vh;
        
        .title-view {
            h1, h2, h3, h4, h5 {
                text-shadow: 2px 2px 2px #000;
            }
            .fpboxes {
                .card {
                    @extend .rgba-black-strong;
                    text-align: center;
                    @extend .z-depth-1-half;

                }
            }
            .bgimage {
                background-size: cover;
                background-position: center;
                -webkit-filter: blur(3px);
                -moz-filter: blur(3px);
                -o-filter: blur(3px);
                -ms-filter: blur(3px);
                filter: blur(3px);
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: -1;
            }
            .full-bg-img {
                background-position: center center;
                // -webkit-filter: blur(5px);
                // -moz-filter: blur(5px);
                // -o-filter: blur(5px);
                // -ms-filter: blur(5px);
                // filter: blur(5px);
            }
            ul {
                @extend .list-unstyled;
            }
        }
    }
    &.index {
        .view {
            height: 50vh;
            @include media-breakpoint-down(md)  {
                height: 80vh;
            }
        }
    }
}