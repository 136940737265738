/* billistan */
/* bilar */

#bilar {
    .sorting {
        .sort span {
            @extend %fa-icon;
            @extend .fas;
        }
        .sort.asc span:before {
            margin-right:10px;
            font-family: 'Font Awesome 5 Free';
            content: fa-content($fa-var-sort-up);
        }
        .sort.desc span:before {
            margin-right:10px;
            font-family: 'Font Awesome 5 Free';
            content: fa-content($fa-var-sort-down);
        }
    }
}
/* #bilar {
    .cars {
        .grid {
            .mix {
                a.carcontainer {
                    color: black;
                    display: block;
                    .carimage {
                        position: relative;
                        overflow: hidden;
                        .carplace {
                            @extend .z-depth-1;
                            background: $brand-color;
                            color: rgb(255, 255, 255);
                            padding: 0;
                            text-align: center;
                            font-weight: 600;
                            position: absolute;
                            text-transform: uppercase;
                            font-weight: bold;
                            width: 220px;
                            -webkit-transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            -ms-transform: rotate(-45deg);
                            -o-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                            top: 35px;
                            left: -65px;
                            &.haninge {
                                background: $grey-darken-3;
                            }
                        }
                    }
                }
            }
        }
    }
} */
